.video-player {
  width: 100%;
  position: relative;
  text-align: center;
}

.video-player__video {
  margin-top: 1rem;
  max-width: 100%;
  margin: 0 auto;
}

.video-player__thumbnail-container {
  margin: 0 auto;
  text-align: center;
  position: absolute;
  width: 100%;
  background-color: white;
  top: 0;
}

.video-player__thumbnail-container__thumbnail {
  position: relative;
  display: inline-block;
}


.video-player__icon-container {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

}

.video-player__icon-container__play-icon {
  width: 10rem;
  height: 10rem;
  fill: white;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}


.react-thumbnail-generator {
  display: block;
}

.react-thumbnail-generator img {
  display: block;
  max-width: 100%;
}